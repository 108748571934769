@import "../../../Variables";

.about-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.about,
.skills {
  height: 100%;
  border-radius: 2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.about {
  > .about-me {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    > .about-text {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.skills {
  width: 35%;

  > .skills-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    > .skills-list {
      overflow-y: scroll;
      height: 80%;
    }
  }
}
