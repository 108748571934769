$base-white: #e4ecf2;
$primary-blue: #c6ddf0;
$secondary-blue: #466995;
$active-color: #2c0735;
$white-background: rgb(228, 236, 242);
$white-gradient: linear-gradient(135deg, rgba(228, 236, 242, 1) 00%, rgba(208, 208, 208, 1) 100%);

$cream-background: rgb(255, 255, 255);
$cream-gradient: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 235, 1) 100%);

$dark-background: rgb(34, 34, 34);
