@import "../../../Variables";

.slider-container {
  width: 80%;
  margin-left: 10%;
}
.project-content {
  width: 100%;
}

.project {
  margin-bottom: 1rem;
  height: 55vh;
  overflow-y: auto;
  padding: 0 3rem;
  box-sizing: border-box;
}

.project-title {
  color: $secondary-blue;
}

@media only screen and (max-width: 1024px) {
  .project {
    height: 60vh;
    padding: 0 1rem;
  }
}
