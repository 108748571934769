.contact-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.right-alight {
  text-align: right;
  padding: 0.5rem 1rem;
}

.map {
  height: 25vh;
  width: 25vh;
  border-radius: 2rem;
}
