@import "../../Variables";

.button {
  background-color: transparent;
  border: $secondary-blue 1px solid;
  color: $secondary-blue;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin: 1rem;

  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out;

  > a {
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    cursor: pointer;
    color: $base-white;
    border-color: transparent;
    background-color: $secondary-blue;
  }

  &:focus {
    outline: none;
  }
}
