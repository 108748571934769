@import "../../Variables";

.navbar {
  width: 100%;

  &:hover i {
    opacity: 0.5;
  }

  > .container {
    margin-right: 10%;
    padding: 2rem 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.link-text,
.link-active {
  margin-left: 5%;
  font-size: 1em;
  animation: slide-in-top ease-in-out;

  color: inherit;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    color: $secondary-blue;
  }
}

.nav-delay-1 {
  animation-duration: 2s;
}

.nav-delay-2 {
  animation-duration: 2.25s;
}

.nav-delay-3 {
  animation-duration: 2.5s;
}

.nav-delay-4 {
  animation-duration: 2.75s;
}

.link-active {
  text-decoration: underline;
}

@keyframes slide-in-top {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  75% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
